<template>
  <div>
    <el-button v-if="this.tableStatus==0&&checkPermission(['ADMIN','COMMUNITYCURRPAIR_ALL','COMMUNITYCURRPAIR_CHECK'])" size="mini" type="success" @click="to(1)">匹配</el-button>
    <el-button v-if="this.tableStatus!=0&&checkPermission(['ADMIN','COMMUNITYCURRPAIR_ALL','COMMUNITYCURRPAIR_RECHECK'])" size="mini" type="warning" @click="to(2)">重新匹配</el-button>
    <el-button v-if="this.tableStatus!=0" size="mini" type="success" @click="to(3)">查看</el-button>
    <eForm
      ref="form"
     :stanCruuPairs="stanCruuPairs"
     :sup_this="sup_this"
     :is-add="false"
    />
  </div>
</template>
<script>
import eForm from './form'
import checkPermission from '@/utils/permission'
import { queryMtAccountSymbols} from '@/api/community/srCommunityCurrPair'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    stanCruuPairs: {
      type: Array,
      required: true
    },
    tableStatus: {
      type: Number,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    }
  },
  methods: {
    checkPermission,
    to(oper) {
      let tableStatus = oper==2?0:this.tableStatus
      const params={'mtAccountId':this.data.id}
      queryMtAccountSymbols(params).then(res=>{
        var currPairs= res;
        const _this = this.$refs.form
        _this.tableStatus = tableStatus,
        _this.form = {
          id: this.data.id,
          userId: this.data.userId,
          phone: this.data.phone,
          email: this.data.email,
          login: this.data.login,
          siteName: this.data.siteName,
          platformName: this.data.platformName,
          symbolStatus: this.data.symbolStatus,
          currPairs:currPairs
        }
        _this.dialog = true
      }).catch(err => {
      })

    }
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
