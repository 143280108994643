<template>
  <el-autocomplete
    class="search-community-box filter-item"
    v-model="siteName"
    :fetch-suggestions="querySearchAsync"
    placeholder="请输入社区名字查询"
    clearable
  >
    <template slot-scope="{ item }">
      <div>{{ item.value=item.siteName }}</div>
    </template>
  </el-autocomplete>
</template>

<script>
  import { querySiteThroughtName } from '@/api/cms/srSite'
  export default {
    name: 'searchCommunity',
    data() {
      return {
        siteName: '',
        timeout: null,
        communityList: []
      }
    },
    computed:{
      siteId(){
        if(!this.communityList.length){
          return '';
        }else {
          for (let i = 0; i < this.communityList.length; i++) {
            if(this.siteName == this.communityList[i].siteName){
              return this.communityList[i].siteId;
              break;
            }
          }
        }
      },
    },
    methods: {
      querySearchAsync(queryString, cb) {
        querySiteThroughtName({
          siteName: queryString,
        }).then((res) => {
          this.communityList = res;
        });

        this.timeout = setTimeout(() => {
          cb(this.communityList);
        }, 1000);
      },
    }
  }
</script>

<style scoped>

</style>
