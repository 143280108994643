import request from '@/utils/request'

export function queryMtAccountSymbols(params) {
  return request({
    url: '/community/crm/srCommunityMtaccount/queryMtAccountAllSymbols',
    method: 'get',
    params
  })
}

export function queryStandardSymbols() {
  return request({
    url: '/community/crm/srCommunityMtaccount/queryStandardSymbols',
    method: 'get'
  })
}

export function updateMtAccountSymbols(data) {
  return request({
    url: '/community/crm/srCommunityMtaccount/updateMtAccountSymbols',
    method: 'post',
    data
  })
}
