<template>
  <div class="app-container">
    <eHeader ref="header" :query="query"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" border style="width: 100%;">
      <el-table-column prop="id" label="id" width="60"/>
      <el-table-column prop="userId" label="客户ID" width="60"/>
      <!-- <el-table-column prop="phone" label="客户电话"/>
      <el-table-column prop="email" label="客户邮箱"/> -->
      <el-table-column prop="siteName" label="所属社区"/>
      <el-table-column prop="platformName" label="绑定平台"/>
      <el-table-column prop="login" label="MT4" width="100"/>
      <el-table-column prop="symbolStatus" label="状态">
        <template slot-scope="scope">
          <span>{{scope.row.symbolStatus=='0'?'无需处理':scope.row.symbolStatus=='1'?'待处理':'已处理' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="绑定时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100px" align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','COMMUNITYCURRPAIR_ALL','COMMUNITYCURRPAIR_SELECT','COMMUNITYCURRPAIR_RECHECK','COMMUNITYCURRPAIR_CHECK'])" :data="scope.row" :sup_this="sup_this" :tableStatus="tableStatus" :stanCruuPairs="stanCruuPairs"/>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import { initData } from '@/api/data'
import { parseTime } from '@/utils/index'
import { queryStandardSymbols} from '@/api/community/srCommunityCurrPair'
import eHeader from '@/components/community/customer/currpairmgr/header'
import edit from '@/components/community/customer/currpairmgr/edit'
export default {
  components: { eHeader, edit },
  props:{
    tableStatus: {
      type: Number,
      required: true
    },
    refresh: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      delLoading: false, sup_this: this,
      stanCruuPairs:[],
      loading: true,
      data: [],
      page: 0,
      size: 10,
      total: 0,
      url: '',
      params: {},
      query: {},
      time: 170
    }
  },
  watch:{
    refresh( newData,oldData){
        this.init()
    }
  },
  mounted() {
    this.init();
    this.queryStandardSymbols();
  },
  methods: {
    parseTime,
    checkPermission,
    init(){
      this.url = '/community/crm/srCommunityMtaccount/queryAll'
      const sort = 'id,desc'
      this.params = { page: this.page, size: this.size, sort: sort }
      const query = this.query
      const userId = query.userId
      const phone = query.phone
      const login = query.login
      if(this.tableStatus=='0'){
          this.params['symbolStatus'] = 1;
      }
      if (userId!=null && userId!='') { this.params['userId'] = userId };
      if (phone !='' ) { this.params['phone'] = phone };
      if (login !='' ) { this.params['login'] = login };
      console.log(this.$refs.header);
      if (this.$refs.header.$refs.searchcommunity.siteId){
        this.params['siteId'] = this.$refs.header.$refs.searchcommunity.siteId;
      }
      initData(this.url, this.params).then(res => {
          this.total = res.totalElements
          this.data = res.content
          this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    queryStandardSymbols(){
      queryStandardSymbols().then(res => {
        this.stanCruuPairs = res
        }).catch(err => {
        })
    },
    pageChange(e) {
      this.page = e - 1
      this.init()
    },
    sizeChange(e) {
      this.page = 0
      this.size = e
      this.init()
    }
  }
}
</script>

<style scoped>

</style>
