<template>
  <div class="app-container">
   <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
     <el-tab-pane v-if="checkPermission(['ADMIN','COMMUNITYCURRPAIR_ALL','COMMUNITYCURRPAIR_SELECT','COMMUNITYCURRPAIR_RECHECK'])" label="全部列表" name="one">
        <tableView :refresh="refresh1" :tableStatus='-1' :visCheck='false'/>
      </el-tab-pane>
      <el-tab-pane v-if="checkPermission(['ADMIN','COMMUNITYCURRPAIR_ALL','COMMUNITYCURRPAIR_CHECK'])" label="待确认" name="two">
        <tableView :refresh="refresh2" :tableStatus='0' :visCheck='false'/>
      </el-tab-pane>
  </el-tabs>
  </div>
</template>

<script>
import tableView from '@/components/community/customer/currpairmgr/table'
import checkPermission from '@/utils/permission'
export default {
  name:'currpairmgr',
  components: { tableView },
  data(){
    return {
      activeName:'one',
      refresh1:false,
      refresh2:false,
      refresh3:false,
      refresh4:false
    }
  },
  methods:{
    checkPermission,
    handleClick(tab, event) {
        switch(tab.name){
          case 'one':
            this.refresh1=!this.refresh1
          break;
          case 'two':
            this.refresh2=!this.refresh2
          break;
          case 'three':
            this.refresh3=!this.refresh3
          break;
          case 'four':
            this.refresh4=!this.refresh4
          break;
        }
      }
  }
}
</script>

<style scoped>

</style>
