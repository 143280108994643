<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" :title="tableStatus==0?'货币对匹配确认':'货币对查看'" width="1000px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="80px">
      <el-row >
        <el-col :span="6">
          <el-form-item label="客户ID">
            <el-input v-model="form.userId" :disabled="true"/>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="6">
          <el-form-item label="客户电话">
            <el-input v-model="form.phone"  :disabled="true"/>
          </el-form-item>
        </el-col> -->
        <el-col :span="6">
          <el-form-item label="绑定平台">
            <el-input v-model="form.platformName" :disabled="true"/>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="MT4">
            <el-input v-model="form.login" :disabled="true"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row >
        <el-table
          :data="form.currPairs"
          size="mini"
          height="400"
          border
          style="width: 100%">
          <el-table-column
            prop="id"
            label="序号"
            width="100">
          </el-table-column>
          <el-table-column
            prop="symbol"
            label="平台产品名称">
          </el-table-column>
          <el-table-column
            prop="symbolDesc"
            label="中文参考名">
          </el-table-column>
          <el-table-column
            prop="standardSymbol"
            label="标准产品名">
            <template slot-scope="scope">
              <el-select
                  size="mini"
                  v-model="scope.row.standardSymbol"
                  placeholder="请选择"
                  filterable
                  clearable
                  :disabled="tableStatus!=0"
                  @clear="clearHandle(scope.$index)"
                  @change="(val) => selectChange(val, scope.$index)"
                >
                <el-option
                    v-for="item in stanCruuPairs"
                    :key="item.symbol"
                    :label="item.symbol"
                    :value="item.symbol"
                >
                  </el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table>
       </el-row>
    </el-form>
    <div v-if="tableStatus==0" slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit()">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { updateMtAccountSymbols} from '@/api/community/srCommunityCurrPair'
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
    stanCruuPairs: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false, dialog: false,isEdit:false,tableStatus:0,
      form: {
        id: '',
        userId: '',
        phone: '',
        email: '',
        login: '',
        siteName: '',
        platformName: '',
        symbolStatus: '',
        currPairs:[]
      },
      rules: {
      },
    }
  },
  watch:{
    tableStatus(newVal,oldVal){
      this.isEdit = newVal==0?false:true
      console.log(this.isEdit)
    }
  },
  methods: {
    updateMtAccountSymbols,
    cancel() {
      this.resetForm()
    },
    selectChange(value,index) {
      let symbolTypIndex = this.stanCruuPairs.findIndex((item) => {
        return item.symbol == value;
      });
      if(symbolTypIndex > -1){
        this.$set(this.form.currPairs[index],'symbolType',this.stanCruuPairs[symbolTypIndex].symbolType);
      }
    },
    clearHandle(index){
      this.$set(this.form.currPairs[index],'symbolType',null);
    },
    doSubmit() {
      this.loading = true
      let params = {
        mtAccountId: this.form.id,
        symbolS: this.form.currPairs
      }
      this.updateMtAccountSymbols(params).then(res => {
        this.resetForm()
        this.$notify({
          title: '匹配成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
      })
    },
    doEdit() {
      updateMtAccountSymbols(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '匹配成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {
        id: '',
        userId: '',
        phone: '',
        email: '',
        login: '',
        siteName: '',
        platformName: '',
        symbolStatus: '',
        currPairs:[]
      }
    }
  }
}
</script>

<style scoped>

</style>
